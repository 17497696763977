// https://backstage.io/docs/getting-started/app-custom-theme/
import {
  createBaseThemeOptions,
  createUnifiedTheme,
  palettes,
  defaultTypography
} from '@backstage/theme';

import LexendDecaRegular from '../assets/fonts/LexendDeca-Regular.woff2';
import LexendDecaBold from '../assets/fonts/LexendDeca-Bold.ttf';

const LexendDecaRegularFont = {
  fontFamily: 'LexendDeca-Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('LexendDeca-Regular'),
    url(${LexendDecaRegular}) format('woff2'),
  `,
};

const LexendDecaBoldFont = {
  fontFamily: 'LexendDeca-Bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('LexendDeca-Bold'),
    url(${LexendDecaBold}) format('woff2'),
  `,
};

export const openCloud = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: palettes.dark,
  }),
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [LexendDecaRegularFont, LexendDecaBoldFont],
      },
    },
  },
  typography: {
    ...defaultTypography,
    htmlFontSize: 16,
    fontFamily: 'LexendDeca-Regular',
    h1: {
      fontSize: 72,
      fontWeight: 700,
      marginBottom: 10,
      fontFamily: 'LexendDeca-Bold',
    },
  },
  defaultPageTheme: 'home',
});
